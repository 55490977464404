import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";

const customStyles = {
  control: (provided) => ({
    ...provided,
    width: 205,
  }),
};

export function CarSelect({ value, onChange, field, make }) {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleChange = (selectedOption) => {
    onChange(field, { _id: selectedOption.value } || "");
  };

  const fetchOptions = async (endpoint, mapFunction) => {
    setLoading(true);
    try {
      const response = await axios.get(endpoint, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      });
      const data = response.data;
      setOptions(data.list.map(mapFunction));
    } catch (error) {
      console.error("Error fetching options:", error);
      setOptions([]);
    } finally {
      setLoading(false);
    }
  };
  const handleFetchOptions = (field, make) => {
    switch (field) {
      case "make":
        fetchOptions(
          `${process.env.REACT_APP_CAR_SERVICE}/api/car/makes`,
          (item) => ({ value: item._id, label: item.name })
        );
        break;
      case "model":
        if (make) {
          fetchOptions(
            `${process.env.REACT_APP_CAR_SERVICE}/api/car/models/${make}`,
            (item) => ({
              value: item._id,
              label: item.model ?? item.name,
            })
          );
        } else {
          setOptions([]);
        }
        break;

      default:
        setOptions([]);
        break;
    }
  };

  useEffect(() => {
    handleFetchOptions(field, make);
  }, [field, make]);

  return (
    <Select
      styles={customStyles}
      value={options.find((opt) => opt.value === value) || null}
      onChange={handleChange}
      options={options}
      isLoading={loading}
      isClearable
    />
  );
}
