import React, { useState } from "react";

const SelectUser = ({ defaultValue, suggestions, onChange, disabled }) => {
  const [inputValue, setInputValue] = useState(defaultValue);
  const [filteredSuggestions, setFilteredSuggestions] = useState(
    defaultValue
      ? suggestions.filter(
          (item) =>
            item.name?.toLowerCase().includes(defaultValue.toLowerCase()) ||
            item.email?.toLowerCase().includes(defaultValue.toLowerCase())
        )
      : []
  );

  const handleChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    if (value) {
      setFilteredSuggestions(
        suggestions.filter(
          (item) =>
            item.name?.toLowerCase().includes(value.toLowerCase()) ||
            item.email?.toLowerCase().includes(value.toLowerCase())
        )
      );
    } else {
      setFilteredSuggestions([]);
    }
  };

  const handleSelect = (value) => {
    setInputValue(value.name);
    setFilteredSuggestions([]);
    onChange(value.id);
  };

  return (
    <div className="relative w-full">
      <input
        disabled={disabled}
        type="text"
        value={inputValue}
        onChange={handleChange}
        placeholder="Nom ou prénom de l'utilsateur / e-mail"
        className="flex-1 box box-border w-full h-[42px] border-2 p-5 rounded-lg border-[#F0F1F7]"
      />
      {filteredSuggestions.length > 0 && !disabled && (
        <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded-lg shadow-md max-h-40 overflow-y-auto mt-1">
          {filteredSuggestions.map((item, index) => (
            <li
              key={index}
              className="px-4 py-2 hover:bg-blue-100 cursor-pointer"
              onClick={() => handleSelect(item)}
            >
              {item.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SelectUser;
