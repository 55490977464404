import * as type from "./actionTypes";
import axios from "axios";
import Swal from "sweetalert2";
import {
  ANALYZED_CARS_IMAGES_FINISHED,
  ANALYZED_CARS_IMAGES_LOADING,
} from "./actionTypes";

let intervalId;

export const addCar = (newCarData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "x-auth-token": `${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({
      ...newCarData,
      make: newCarData.make._id,
      model: newCarData.model._id,
    });
    const carData = await axios.post(
      `${process.env.REACT_APP_CAR_SERVICE}/api/car`,
      body,
      config
    );

    dispatch({
      type: type.GET_CAR,
      car: carData.data.car,
    });
  } catch (error) {
    console.error(error);
  }
};
export const addCarConfirmation = (payload) => async (dispatch) => {
  dispatch({
    type: type.IS_NEXT_CONFIRMED,
    isNextConfirmed: payload,
  });
};
export const getCar = (id) => async (dispatch) => {
  try {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_CAR_SERVICE}/api/car/${id}`,
      headers: {
        "x-auth-token": `${localStorage.getItem("token")}`,
      },
    };
    const getCarDetails = await axios(config);
    dispatch({
      type: type.GET_CAR,
      car: getCarDetails.data,
    });
    dispatch({
      type: "INIT_FORM",
      payload: getCarDetails.data,
    });
  } catch (error) {
    // to enhance upon completion
  }
  // setSelectedData(getCarDetails.data.feature);
};
export const editCar = (id, carEditedData) => async (dispatch) => {
  // car ID and timestamps should be deleted before request is sent but we also need the id for the url Request
  delete carEditedData._id;
  delete carEditedData.updatedAt;
  delete carEditedData.createdAt;
  delete carEditedData.__v;
  try {
    let config = {
      method: "patch",
      url: `${process.env.REACT_APP_CAR_SERVICE}/api/car/${id}`,
      data: {
        ...carEditedData,
        make: carEditedData.make._id,
        model: carEditedData.model._id,
      },
      headers: {
        "x-auth-token": `${localStorage.getItem("token")}`,
      },
    };
    const editCar = await axios(config);
    dispatch({
      type: type.GET_CAR,
      car: editCar.data.car,
    });
    dispatch({
      type: type.EDIT_CAR_SUCCESS,
      success: editCar.data.car,
    });
  } catch (error) {}
};

export const getCarCount = () => async (dispatch) => {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_CAR_SERVICE}/api/car/totalCarCount`,
    headers: {
      "x-auth-token": localStorage.getItem("token"),
    },
  };
  await axios(config)
    .then((response) => {
      dispatch({
        type: type.GET_CARS_COUNT,
        totalCarsCount: response.data.totalCarsCount,
      });
      dispatch({
        type: type.GET_CARS_COUNT,
        totalCarsCount: response.data.totalCarsCount,
      });
      dispatch({
        type: type.ADD_CAR_NUMBER,
        adNumber: response.data.totalCarsCount,
      });
    })
    .catch((error) => {
      Swal.fire("Erreur de chargement du nombre des annonces");
    });
};
export const GetCarDamageImages = (carId) => async (dispatch) => {
  let getCarDamageImage = {
    method: "get",
    url: `${process.env.REACT_APP_CAR_SERVICE}/api/car/getDamagedCarImages/${carId}`,
    headers: {
      "x-auth-token": `${localStorage.getItem("token")}`,
    },
  };
  await axios(getCarDamageImage).then((response) => {
    dispatch({
      type: "DAMAGED_CAR_IMAGE",
      damagedCarImages: response.data || [],
    });
  });
};

export const uploadCarDamageImages = (filename) => async (dispatch) => {
  dispatch({
    type: "UPLOAD_START_DAMAGED_CAR_IMAGE",
    payload: filename,
  });
};

export const uploadFinishedCarDamageImages =
  (filename, image_link) => async (dispatch) => {
    dispatch({
      type: "UPLOAD_FINISH_DAMAGED_CAR_IMAGE",
      payload: {
        filename,
        image_link,
      },
    });
  };

export const clearCarDamageImages = () => async (dispatch) => {
  dispatch({
    type: "CLEAR_DAMAGED_CAR_IMAGE",
  });
};

export const deleteCarDamageImage = (filename) => async (dispatch) => {
  dispatch({
    type: "DELETE_DAMAGED_CAR_IMAGE",
    payload: {
      filename,
    },
  });
};

export const getAnalyzedCarImages = (carId) => async (dispatch, getState) => {
  try {
    const loading = getState().cars.analysedCarImagesLading;
    if (loading) {
      return;
    }

    dispatch({
      type: type.ANALYZED_CARS_IMAGES_LOADING,
    });

    let getAnalyzedCarImagesParam = {
      method: "get",
      url: `${process.env.REACT_APP_CAR_SERVICE}/api/car/getAnalyzedCarImages/${carId}`,
      headers: {
        "x-auth-token": `${localStorage.getItem("token")}`,
      },
    };
    const analyzedImages = await axios(getAnalyzedCarImagesParam);

    dispatch({
      type: type.ANALYZED_CARS_IMAGES,
      analysedCarImages: analyzedImages.data || [],
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: type.ANALYZED_CARS_IMAGES_ERROR,
      analysedCarImagesError: e,
    });
  }
};

// export const stopGetAnalyzedCarImages = () => async (dispatch) => {
//   clearInterval(intervalId);
//   dispatch({
//     type: type.ANALYZED_CARS_IMAGES_FINISHED,
//   });
// }

export const ClearStoreData = (payload) => async (dispatch) => {
  dispatch({
    type: "CLEARSTORE",
    payload: payload,
  });
};
