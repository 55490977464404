import React, { useRef, useEffect } from "react";
import Flickity from "react-flickity-component";
import "flickity/css/flickity.css";
import throttle from "lodash/throttle";

const SteerSlider = ({ steerImages, options, preventDefaultActive }) => {
  const flickityRef = useRef(null);

  // Event handler for local mouse movement
  const handleMouseMove = throttle((event) => {
    if (!preventDefaultActive) {
      event.preventDefault();
    }
    const flickityLeft =
      flickityRef.current.element.getBoundingClientRect().left;
    const flickityRight =
      flickityRef.current.element.getBoundingClientRect().right;
    const width = flickityRef.current.element.getBoundingClientRect().width;
    const mouseX = event.clientX;
    const mouseXrelative = mouseX - flickityLeft;
    const percentage = 1 - mouseXrelative / width;
    // Calculate target image index based on mouse position
    const targetIndex = Math.floor(percentage * steerImages.length);

    if (mouseX >= flickityLeft && mouseX <= flickityRight) {
      flickityRef.current.select(targetIndex, false, true);
    }
  }, 10);

  // Event handlers for mouse click and release
  const handleMouseDown = () => {
    const flickityInstance = flickityRef.current;
    flickityInstance.element.style.cursor = "grabbing";
  };

  const handleMouseUp = () => {
    // Reset cursor icon when mouse is released
    const flickityInstance = flickityRef.current;
    flickityInstance.element.style.cursor = "grab";
  };

  useEffect(() => {
    const flickityInstance = flickityRef.current;
    // Attach events to the Flickity DOM element
    if (flickityInstance) {
      flickityInstance.on("pointerMove", handleMouseMove);
      flickityInstance.on("pointerDown", handleMouseDown);
      flickityInstance.on("pointerUp", handleMouseUp);
    }

    return () => {
      // Cleanup: Remove events when the component unmounts
      if (flickityInstance) {
        flickityInstance.off("pointerMove", handleMouseMove);
        flickityInstance.off("pointerDown", handleMouseDown);
        flickityInstance.off("pointerUp", handleMouseUp);
      }
    };
  }, [preventDefaultActive]);

  return (
    <Flickity
      className="slider"
      elementType="div"
      options={options}
      disableImagesLoaded={false}
      reloadOnUpdate
      flickityRef={(c) => (flickityRef.current = c)}
    >
      {steerImages.map((image, index) => (
        <div
          key={index}
          className="gallery-cell"
          style={{ width: "100%", textAlign: "center" }}
        >
          <img
            src={image}
            alt={`Image ${index + 1}`}
            style={{
              maxWidth: "100%",
              maxHeight: "450px",
            }}
          />
        </div>
      ))}
    </Flickity>
  );
};

export default SteerSlider;
