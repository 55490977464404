import React from "react";
import Webcam from "react-webcam";

const VideoCaptureModal = ({
  open,
  close,
  webcamRef,
  isCaptured,
  isRecording,
  handleStartCapture,
  handleStopCapture,
  videoConstraints,
}) => {
  if (!open) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-96">
        <h2 className="text-xl font-bold mb-4 text-center">Capture Video</h2>

        <Webcam
          ref={webcamRef}
          videoConstraints={videoConstraints}
          className="w-full rounded-md mb-4"
        />

        <div className="flex flex-col gap-3">
          <button
            onClick={isRecording ? handleStopCapture : handleStartCapture}
            className={`px-4 py-2 rounded-md text-white ${
              isRecording ? "bg-red-600 hover:bg-red-700" : " bg-blue1"
            }`}
          >
            {isRecording
              ? "Finish Recording"
              : isCaptured
              ? "Retake a new view"
              : "Start Recording"}
          </button>
          <button
            onClick={close}
            className="px-4 py-2 rounded-md bg-gray-300 hover:bg-gray-400"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default VideoCaptureModal;
